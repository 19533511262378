.c-textblock {
	padding:100px 0px;
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;

	& > * {
		text-align:center;
	}


	img {
		width:80px;
		margin-bottom: 40px;
	}

	&__content {
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items: center;
	}
}