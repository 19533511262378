.c-liftup {
	padding:80px 0px 0px;

	ul {
	display: flex;
	flex-flow: row wrap;
	justify-content:space-between;

		li {
			width:31%;
			text-align:left;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
			overflow:hidden;
			height: 450px;
			position:relative;
			margin-bottom:40px;
			position:relative;
			cursor:pointer;

			&.no-picture {
				background:lighten($grey-very-dark, 5%);
				background-size:contain;
				background-position:top center;
				background-repeat:no-repeat;
			}

			@include mq('medium') {
			    width: 100%;
			    max-width: 500px;
			    margin: 0 auto 40px;
			    background-position: top center;
			}

			@include mq('small') {
			    min-height:375px;
			}

			&:before {
				content: "";
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    right: 0;
			    left: 0;
			    z-index: 2;
			    background: -webkit-linear-gradient(45deg,rgba(237,28,36,.7),rgba(0,0,0,.5));
			    background: linear-gradient(45deg,rgba(237,28,36,.7),rgba(0,0,0,.5));
			    opacity: 0;
			    -webkit-transition: all .3s ease-in-out;
			    transition: all .3s ease-in-out;
			}

			&:hover:before {
				opacity: 0.5;
			}
		}
	}

	&.smaller {

		li {
			height: 350px !important;
		}
	}

	&__content {
		background:$grey-very-dark;
		height:100%;
		display: flex;
		flex-flow:column nowrap;
		justify-content: space-between;
		transform:translateY(100%);
		margin-top:-48px;
		transition:all 750ms ease;
		transition-delay:200ms;
		position:relative;
		z-index: 2;

		&.active {
			transform:translateY(0%);
			margin-top:0px;

			.c-liftup__content-name {

				&:after {
					transform:rotate(0deg);
				}
			}
		}
		
		&-name {
			padding:12px 30px;
			font-family: $circular;
			font-size:1.6rem;
			line-height: 2.4rem;
			font-weight:400;
			color:white;
			position:relative;
			height: 48px;

			&:before,
			&:after {
				content:'';
				position:absolute;
				top:0px;
				bottom:0px;
				right:30px;
				margin:auto 0;
				width:10px;
				height:2px;
				background:$red;
			}

			&:after {
				transform:rotate(90deg);
				transition:all 100ms ease-in-out;
			}
		}

		&-description {
			padding:20px 30px;

			p {
				font-size: 1.4rem;
				line-height: 2rem;
				color:$grey-light;

				@include mq('xsmall') {
					font-size:1.2rem;
					line-height: 1.8rem;
				}
			}
		}

		&-link {

			a {
				display: block;
				padding:0px 30px 20px;
				font-size:1.4rem;
				line-height: 2rem;
				color:$grey-light;
				transition:all 300ms ease;
				font-family: $circular;

				i {
					color:$red;
					margin-left:8px;
				}

				&:hover {
					color:$grey-light;
				}
			}

			.c-button--arrow {
				text-transform:initial;
			}
		}

	}
}

.services {

	.c-liftup {
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		padding:60px 40px;
	}
}