/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1100px),
	'xlarge': 		(max-width: 1199px),
	'xxl': 			(max-width: 1439px)
);


/* =======================================================================
Font-face
========================================================================== */

@font-face {
	font-family: 'circular';
	src: url('../fonts/circular/circular.ttf') format("truetype"),
		 url('../fonts/circular/circular.woff') format("woff");
}

@font-face {
	font-family: 'knockout';
	src: url('../fonts/knockout/knockout.otf') format("opentype");
}

@font-face {
	font-family: 'knockout-bold';
	src: url('../fonts/knockout/knockout-bold.otf') format("opentype");
}


/* =======================================================================
Variables
========================================================================== */

// Colors
$main-color: 			  #ff0000;
$white:					  #fcfcfc;
$grey:					  #656565;
$grey-light:			  #c6c6c6;
$grey-dark:				  #272727;
$grey-very-dark:		  #0c0c0c;
$grey-border:			  #2e2e2e;
$grey-border-2:			  #212121;
$grey-mobile:			  #171717;
$red:				  	  #ed1c24;

// Fonts stacks
$font-stack:              Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              $main-color;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);

// Custom fonts
$circular:				  'circular';
$knockout:				  'knockout';
$knockout-b:			  'knockout-bold';
