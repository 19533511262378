.c-button--arrow {
	@extend .c-button;
}

.c-button {
	display:inline-block;
	font-family:$knockout-b;
	color:$white;
	font-size:1.6rem;
	text-transform:uppercase;
	padding:15px 25px;
	border:2px solid $red;
	transition:all 300ms ease-in-out;

	@include mq('xsmall') {
		padding:15px 15px;
	}
	
	span {
		padding:0px 10px;
	}


	&:hover {
		color:$grey-light;
	}

	&:visited {
		color:$grey-light;
	}

	i {
		color:$red;
		position:relative;

		.c-button__red-border {
			content:'';
			position:absolute;
			display: block;
			top:0px;
			bottom:0px;
			margin:auto 0;
			right:3px;
			height:3px;
			background-color:$red;
			width:15px;
			transition:all 300ms ease-in-out;
		}
	}

	&.no-border {
		border:0px;
		display: block;

		@include mq('xsmall') {
			padding:15px 0px;
		}
	}
}