/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: 'circular';
  src: url("../fonts/circular/circular.ttf") format("truetype"), url("../fonts/circular/circular.woff") format("woff");
}

@font-face {
  font-family: 'knockout';
  src: url("../fonts/knockout/knockout.otf") format("opentype");
}

@font-face {
  font-family: 'knockout-bold';
  src: url("../fonts/knockout/knockout-bold.otf") format("opentype");
}

/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 19, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/dm-metal/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
html {
  font-size: 10px;
}

/* line 5, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
html,
button,
input,
select,
textarea {
  color: #333;
  font-family: Arial, Verdana, sans-serif;
}

/* line 14, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
body {
  background: black;
  font-size: 16px;
  line-height: 1.4;
  padding: 0px 30px;
  margin: 0px;
  position: relative;
}

@media (max-width: 479px) {
  /* line 14, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  body {
    padding: 0px 20px;
  }
}

/* line 27, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 33, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 0px;
}

/* line 39, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

/* line 46, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h1, h2 {
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
}

/* line 52, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h1 {
  font-family: "knockout-bold";
  font-size: 6rem;
  line-height: 6.5rem;
  color: #fcfcfc;
  font-weight: 400;
}

@media (max-width: 767px) {
  /* line 52, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h1 {
    font-size: 4.5rem;
    line-height: 5rem;
  }
}

@media (max-width: 479px) {
  /* line 52, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

/* line 69, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h1.size-up {
  font-size: 8.5rem;
  line-height: 8.5rem;
}

@media screen and (min-width: 1200px) {
  /* line 69, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h1.size-up {
    font-size: 9.5rem;
    line-height: 9.5rem;
  }
}

@media (max-width: 767px) {
  /* line 69, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h1.size-up {
    font-size: 5.5rem;
    line-height: 6rem;
  }
}

@media (max-width: 479px) {
  /* line 69, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h1.size-up {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

/* line 90, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h2 {
  font-family: "knockout";
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 5px;
  color: #656565;
}

@media (max-width: 767px) {
  /* line 90, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

/* line 102, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h2.size-up {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

@media (max-width: 767px) {
  /* line 102, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  h2.size-up {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

/* line 113, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
h3 {
  font-weight: bold;
  font-family: "circular";
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #c6c6c6;
}

/* line 121, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
p {
  font-family: "circular";
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #c6c6c6;
}

/* line 127, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
p.size-up {
  font-size: 2.8rem;
  line-height: 3.4rem;
  color: #fcfcfc;
  max-width: 555px;
  margin: 0px 0px 40px;
}

@media (max-width: 767px) {
  /* line 127, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
  p.size-up {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

/* line 141, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
a {
  color: #ff0000;
  text-decoration: none;
}

/* line 144, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
a:hover {
  color: #990000;
}

/* line 145, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
a:focus, a:active {
  color: #330000;
}

/* line 149, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 151, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 160, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 165, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 170, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 176, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
::selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 183, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: black;
  opacity: 1;
}

/* line 187, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: black;
  opacity: 1;
}

/* line 191, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: black;
  opacity: 1;
}

/* line 195, public/wp-content/themes/dm-metal/assets/src/scss/global/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: black;
  opacity: 1;
}

/* =======================================================================
Forms
========================================================================== */
/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/dm-metal/assets/src/scss/global/_layout.scss */
.l-6 {
  width: 50%;
  float: left;
}

/* =======================================================================
Navigation
========================================================================== */
/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: black;
  padding: 20px 0px;
  transform: translateY(0%);
}

/* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header.hide {
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 20px 30px;
}

/* line 20, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header.show {
  transition: transform 300ms ease-in-out;
}

/* line 24, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header.sticky {
  transform: translateY(0%);
}

/* line 28, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__logo {
  max-width: 250px;
  width: 100%;
}

@media (max-width: 479px) {
  /* line 28, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
  .c-header__logo {
    max-width: 160px;
  }
}

/* line 37, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle {
  display: none;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 992px) {
  /* line 37, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
  .c-header__toggle {
    display: inline-flex;
  }
}

/* line 48, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle i {
  color: white;
  font-size: 1.2rem;
  margin-left: 4px;
  transition: all 500ms ease;
}

/* line 54, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle i.first {
  transition-delay: 0ms;
}

/* line 58, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle i.second {
  transition-delay: 100ms;
}

/* line 62, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle i.third {
  transition-delay: 200ms;
}

/* line 71, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle:hover i.first {
  color: #ed1c24;
}

/* line 75, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle:hover i.second {
  color: #ed1c24;
}

/* line 79, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__toggle:hover i.third {
  color: #ed1c24;
}

/* line 87, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 992px) {
  /* line 87, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
  .c-header__nav {
    display: none;
  }
}

/* line 97, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li {
  margin-left: 30px;
  padding-left: 30px;
}

@media (max-width: 1100px) {
  /* line 97, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
  .c-header__nav li {
    padding-left: 15px;
    margin-left: 15px;
  }
}

/* line 106, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li a {
  font-family: "knockout-bold";
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #fcfcfc;
  position: relative;
  padding: 0px 5px;
  transition: all 300ms ease-in-out;
}

/* line 116, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li a:hover {
  color: #c6c6c6;
}

/* line 120, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li a:before {
  content: '';
  display: block;
  width: 0px;
  height: 3px;
  background: #ed1c24;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto 0;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

/* line 136, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li a:hover:before {
  width: 100%;
}

/* line 140, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li a:not(:hover):before {
  width: 0px;
  right: 0px;
  left: initial;
}

/* line 147, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li:last-child {
  border-left: 2px solid #272727;
}

/* line 150, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__nav li:last-child a:before {
  display: none;
}

/* line 158, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile {
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 305px;
  background: #0c0c0c;
  padding: 30px 60px 30px 30px;
  z-index: 99999;
  transform: translateX(-100%);
  transition: all 500ms ease;
}

/* line 174, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile.active {
  transform: translateX(0%);
}

@media (max-width: 992px) {
  /* line 158, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
  .c-header__mobile {
    display: flex;
  }
}

/* line 182, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-close {
  color: white;
  width: 30px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  background: #171717;
  transition: all 300ms ease;
  cursor: pointer;
}

/* line 199, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-close:hover {
  background: #1c1c1c;
}

/* line 203, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-close i {
  color: #ed1c24;
}

/* line 208, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile img {
  max-width: 200px;
  margin-bottom: 40px;
}

/* line 215, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-nav li,
.c-header__mobile-nav li a {
  font-family: "knockout-bold";
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 1px;
  transition: all 300ms ease;
}

/* line 225, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-nav li {
  padding: 10px 0px;
  color: #ed1c24;
}

/* line 229, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-nav li a {
  color: #c6c6c6;
}

/* line 232, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-nav li a:hover {
  color: white;
}

/* line 237, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-header.scss */
.c-header__mobile-nav li:last-child {
  border-top: 2px solid #2e2e2e;
  margin-top: 40px;
  width: 100%;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 0px 0px 1px black inset;
  position: relative;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
  .c-banner {
    height: 400px !important;
  }
}

/* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner .container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

@media (max-width: 767px) {
  /* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
  .c-banner .container {
    align-items: center;
  }
}

/* line 26, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner.interne .container {
  align-items: center;
  justify-content: center;
}

/* line 32, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__content {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}

@media (max-width: 767px) {
  /* line 32, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
  .c-banner__content {
    align-items: center;
    text-align: center;
  }
}

/* line 42, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__content h2 {
  order: -1;
  color: #c6c6c6;
}

/* line 50, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner.interne h1 {
  margin-bottom: 0px;
}

/* line 54, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner.interne .c-banner__content {
  align-items: center;
  justify-content: center;
}

/* line 60, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__scroll {
  position: absolute;
  bottom: 40px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  /* line 60, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
  .c-banner__scroll {
    display: none;
  }
}

/* line 74, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__scroll a {
  font-family: "circular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: #fcfcfc;
  transition: all 300ms ease-in-out;
}

/* line 82, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__scroll a:hover {
  color: #c6c6c6;
}

/* line 88, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-banner.scss */
.c-banner__scroll i {
  display: block;
  text-align: center;
  color: #ed1c24;
  font-size: 3rem;
}

/* line 5, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button, .c-button--arrow {
  display: inline-block;
  font-family: "knockout-bold";
  color: #fcfcfc;
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 15px 25px;
  border: 2px solid #ed1c24;
  transition: all 300ms ease-in-out;
}

@media (max-width: 479px) {
  /* line 5, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
  .c-button, .c-button--arrow {
    padding: 15px 15px;
  }
}

/* line 19, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button span, .c-button--arrow span {
  padding: 0px 10px;
}

/* line 24, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button:hover, .c-button--arrow:hover {
  color: #c6c6c6;
}

/* line 28, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button:visited, .c-button--arrow:visited {
  color: #c6c6c6;
}

/* line 32, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button i, .c-button--arrow i {
  color: #ed1c24;
  position: relative;
}

/* line 36, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button i .c-button__red-border, .c-button--arrow i .c-button__red-border {
  content: '';
  position: absolute;
  display: block;
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  right: 3px;
  height: 3px;
  background-color: #ed1c24;
  width: 15px;
  transition: all 300ms ease-in-out;
}

/* line 51, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
.c-button.no-border, .no-border.c-button--arrow {
  border: 0px;
  display: block;
}

@media (max-width: 479px) {
  /* line 51, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-button.scss */
  .c-button.no-border, .no-border.c-button--arrow {
    padding: 15px 0px;
  }
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-sticky-cta.scss */
.c-sticky__cta {
  width: 60px;
  height: 200px;
  background-color: #ed1c24;
  position: fixed;
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  right: 0px;
  transition: all 300ms ease-in-out;
  z-index: 7;
  transform: translateX(0%);
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-sticky-cta.scss */
  .c-sticky__cta {
    display: none;
  }
}

/* line 18, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-sticky-cta.scss */
.c-sticky__cta:hover {
  background-color: #ef343b;
}

/* line 22, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-sticky-cta.scss */
.c-sticky__cta.slideOut {
  transform: translateX(100%);
}

/* line 26, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-sticky-cta.scss */
.c-sticky__cta a {
  color: #fff;
  font-family: knockout-bold;
  font-size: 1.8rem;
  text-transform: uppercase;
  transform: rotate(-90deg);
  height: 100%;
  width: 200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 20px;
  transition: all 300ms ease-in-out;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-textblock.scss */
.c-textblock {
  padding: 100px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 7, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-textblock.scss */
.c-textblock > * {
  text-align: center;
}

/* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-textblock.scss */
.c-textblock img {
  width: 80px;
  margin-bottom: 40px;
}

/* line 17, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-textblock.scss */
.c-textblock__content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
.c-equipe {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 400px 0px 100px;
  position: relative;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe {
    padding: 200px 0px 50px;
  }
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe {
    padding: 100px 0px;
    text-align: center;
  }
}

@media (max-width: 479px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe {
    padding: 50px 0px;
  }
}

/* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
.c-equipe span.title {
  font-family: "knockout-bold";
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 6rem;
  line-height: 6.5rem;
  color: #fcfcfc;
}

@media screen and (min-width: 1300px) {
  /* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe span.title {
    font-size: 7rem;
    line-height: 7.5rem;
  }
}

@media (max-width: 767px) {
  /* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe span.title {
    font-size: 4.5rem;
    line-height: 5rem;
  }
}

@media (max-width: 479px) {
  /* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe span.title {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

/* line 46, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
.c-equipe p {
  font-size: 1.8rem;
  line-height: 2.4rem;
  max-width: 350px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 46, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe p {
    margin: 20px auto 30px;
  }
}

/* line 57, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
.c-equipe__cta {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 50px;
  background: #0d0d0d;
  z-index: 1;
}

@media (max-width: 767px) {
  /* line 57, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-equipe.scss */
  .c-equipe__cta {
    background: transparent;
    position: initial;
    padding: 0px;
  }
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

/* line 6, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation.mb {
  margin-bottom: 0px;
}

/* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation > a {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}

/* line 17, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation > a:before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 2;
  background: linear-gradient(45deg, rgba(237, 28, 36, 0.7) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0;
  transition: all 300ms ease-in-out;
}

/* line 32, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation > a:hover:before {
  opacity: 0.7;
}

/* line 36, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation > a:hover .c-realisation__bar {
  bottom: 0px;
}

/* line 42, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__half {
  width: 50%;
  height: 350px;
}

@media (max-width: 767px) {
  /* line 42, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
  .c-realisation__half {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 479px) {
  /* line 42, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
  .c-realisation__half {
    height: 200px;
  }
}

/* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__third {
  width: calc(100% / 3);
  height: 300px;
}

@media (max-width: 767px) {
  /* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
  .c-realisation__third {
    width: 100%;
  }
}

@media (max-width: 479px) {
  /* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
  .c-realisation__third {
    height: 200px;
  }
}

/* line 69, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__bar {
  width: 100%;
  padding: 20px;
  background-color: black;
  position: absolute;
  bottom: -65px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease-in-out;
  z-index: 3;
}

/* line 84, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__bar span.project-name {
  font-family: "knockout-bold";
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  color: #fcfcfc;
}

/* line 92, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__bar span.cta {
  font-family: "circular";
  font-size: 1.6rem;
  line-height: 2rem;
  color: #fcfcfc;
}

@media (max-width: 479px) {
  /* line 92, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
  .c-realisation__bar span.cta {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
}

/* line 103, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__bar span.cta span {
  color: #ed1c24;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}

/* line 116, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__dispatch {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #1a1a1a;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* line 125, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-realisation.scss */
.c-realisation__dispatch .c-button, .c-realisation__dispatch .c-button--arrow {
  font-family: "circular";
  text-transform: initial;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
.c-partners {
  padding: 60px 0px 0px;
}

/* line 4, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
.c-partners__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
.c-partners__list li {
  padding: 0px 20px;
  margin-bottom: 40px;
  flex-basis: 25%;
}

@media (max-width: 767px) {
  /* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
  .c-partners__list li {
    flex-basis: 33.33%;
  }
}

@media (max-width: 479px) {
  /* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
  .c-partners__list li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 400px) {
  /* line 10, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
  .c-partners__list li {
    flex-basis: 100%;
  }
}

/* line 27, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-partners.scss */
.c-partners__list li img {
  width: 140px;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
.c-footer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 120px 20px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer {
    text-align: center;
  }
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer {
    padding: 60px 20px;
  }
}

/* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
.c-footer .container {
  padding: 0px 0px;
}

/* line 25, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
.c-footer span.title {
  font-family: "knockout-bold";
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 6rem;
  line-height: 6.5rem;
  color: #fcfcfc;
}

@media screen and (min-width: 1300px) {
  /* line 25, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer span.title {
    font-size: 7rem;
    line-height: 7.5rem;
  }
}

@media (max-width: 992px) {
  /* line 25, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer span.title {
    font-size: 4.5rem;
    line-height: 5rem;
  }
}

@media (max-width: 767px) {
  /* line 25, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer span.title {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

/* line 50, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
.c-footer p {
  font-size: 1.8rem;
  line-height: 2.4rem;
  max-width: 350px;
  margin-bottom: 0px;
}

@media (max-width: 992px) {
  /* line 50, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer p {
    margin: 20px auto 30px;
  }
}

/* line 61, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
.c-footer__cta {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 50px;
  background: black;
  z-index: 1;
}

@media (max-width: 992px) {
  /* line 61, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-footer.scss */
  .c-footer__cta {
    background: transparent;
    position: initial;
    padding: 0px;
  }
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright {
  padding: 60px 0px;
}

/* line 4, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 650px) {
  /* line 4, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
  .c-copyright ul {
    flex-flow: column wrap;
    justify-content: center;
  }
}

/* line 15, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li {
  font-family: "knockout";
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fcfcfc;
}

@media screen and (max-width: 650px) {
  /* line 15, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
  .c-copyright ul li {
    text-align: center;
    margin: 5px 0px;
  }
}

/* line 30, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li:first-child a {
  font-family: "circular";
}

/* line 35, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li:last-child {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 650px) {
  /* line 35, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
  .c-copyright ul li:last-child {
    justify-content: center;
  }
}

/* line 45, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li:last-child svg {
  width: 25px;
  fill: #ed1c24;
  margin: 0px 0px -8px 10px;
}

/* line 52, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li .red {
  color: #ed1c24;
}

/* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li a {
  color: #fcfcfc;
  transition: all 300ms ease-in-out;
}

/* line 60, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-copyright.scss */
.c-copyright ul li a:hover {
  color: #c6c6c6;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-loader.scss */
.c-loader {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  animation-name: loader;
  animation-duration: 1.5s;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* line 21, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-loader.scss */
.c-loader:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ed1c24;
  transform: translateY(100%);
  animation-name: redBox;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 12;
}

/* line 39, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-loader.scss */
.c-loader:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, black 0%, transparent 100%);
  transform: translateY(100%);
  animation-name: fade;
  animation-delay: 3s;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

/* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-loader.scss */
.c-loader img {
  width: 150px;
  animation-name: scale;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes loader {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-200%);
  }
}

@keyframes redBox {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup {
  padding: 80px 0px 0px;
}

/* line 4, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

/* line 9, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup ul li {
  width: 31%;
  text-align: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  height: 450px;
  position: relative;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
}

/* line 22, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup ul li.no-picture {
  background: #191919;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  /* line 9, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
  .c-liftup ul li {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 40px;
    background-position: top center;
  }
}

@media (max-width: 767px) {
  /* line 9, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
  .c-liftup ul li {
    min-height: 375px;
  }
}

/* line 40, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup ul li:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: -webkit-linear-gradient(45deg, rgba(237, 28, 36, 0.7), rgba(0, 0, 0, 0.5));
  background: linear-gradient(45deg, rgba(237, 28, 36, 0.7), rgba(0, 0, 0, 0.5));
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

/* line 55, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup ul li:hover:before {
  opacity: 0.5;
}

/* line 63, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup.smaller li {
  height: 350px !important;
}

/* line 68, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content {
  background: #0c0c0c;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  transform: translateY(100%);
  margin-top: -48px;
  transition: all 750ms ease;
  transition-delay: 200ms;
  position: relative;
  z-index: 2;
}

/* line 81, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content.active {
  transform: translateY(0%);
  margin-top: 0px;
}

/* line 87, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content.active .c-liftup__content-name:after {
  transform: rotate(0deg);
}

/* line 93, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-name {
  padding: 12px 30px;
  font-family: "circular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: white;
  position: relative;
  height: 48px;
}

/* line 103, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-name:before, .c-liftup__content-name:after {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 30px;
  margin: auto 0;
  width: 10px;
  height: 2px;
  background: #ed1c24;
}

/* line 116, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-name:after {
  transform: rotate(90deg);
  transition: all 100ms ease-in-out;
}

/* line 122, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-description {
  padding: 20px 30px;
}

/* line 125, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-description p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #c6c6c6;
}

@media (max-width: 479px) {
  /* line 125, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
  .c-liftup__content-description p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

/* line 139, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-link a {
  display: block;
  padding: 0px 30px 20px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #c6c6c6;
  transition: all 300ms ease;
  font-family: "circular";
}

/* line 148, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-link a i {
  color: #ed1c24;
  margin-left: 8px;
}

/* line 153, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-link a:hover {
  color: #c6c6c6;
}

/* line 158, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.c-liftup__content-link .c-button--arrow {
  text-transform: initial;
}

/* line 168, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-liftup.scss */
.services .c-liftup {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 40px;
}

/* line 7, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
body.services .c-inner-listing ul li:last-of-type {
  border-bottom: 0px;
  margin-top: 20px;
}

/* line 15, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* line 20, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__img {
  height: 700px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  /* line 20, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__img {
    height: 400px;
  }
}

@media (max-width: 767px) {
  /* line 20, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__img {
    height: 300px;
  }
}

@media (max-width: 479px) {
  /* line 20, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__img {
    height: 200px;
  }
}

/* line 40, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing .container.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 60px;
  padding-bottom: 60px;
  background-size: cover;
  background-repeat: repeat;
}

@media (max-width: 767px) {
  /* line 40, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing .container.flex {
    flex-flow: column wrap;
    padding-top: 20px;
  }
}

/* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list {
  width: 33%;
  background: #0c0c0c;
  padding: 53px;
  margin-top: -150px;
}

@media (max-width: 992px) {
  /* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__list {
    padding: 50px 30px;
  }
}

@media (max-width: 767px) {
  /* line 56, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__list {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

/* line 72, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list.higher {
  margin-top: -300px;
}

@media (max-width: 767px) {
  /* line 72, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__list.higher {
    margin-top: 0px;
  }
}

/* line 82, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list ul li {
  padding: 20px 0px;
  font-family: "circular";
  border-bottom: 2px solid #2e2e2e;
  color: #c6c6c6;
  font-size: 1.6rem;
}

/* line 89, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list ul li a {
  color: #c6c6c6;
  transition: all 300ms ease;
}

/* line 93, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list ul li a:hover {
  color: #ed1c24;
}

/* line 97, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__list ul li a i {
  color: #ed1c24;
  margin-left: 10px;
}

/* line 106, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__content {
  width: 60%;
}

@media (max-width: 767px) {
  /* line 106, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing__content {
    width: 100%;
  }
}

/* line 113, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing__content p {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

/* line 119, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
.c-inner-listing .c-tabs {
  width: 62%;
}

@media (max-width: 767px) {
  /* line 119, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-about-equipe.scss */
  .c-inner-listing .c-tabs {
    width: 100%;
    padding: 0px 10px;
  }
}

/* line 3, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu {
  margin-bottom: 40px;
}

@media (max-width: 479px) {
  /* line 3, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__menu {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

/* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a {
  font-family: knockout;
  font-size: 2rem;
  line-height: 2.6rem;
  letter-spacing: 5px;
  color: #656565;
  text-transform: uppercase;
  margin-right: 40px;
  font-weight: bold;
  transition: all 300ms ease;
  position: relative;
}

@media (max-width: 992px) {
  /* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__menu a {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (max-width: 767px) {
  /* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__menu a {
    margin-right: 20px;
  }
}

@media (max-width: 479px) {
  /* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
  .c-tabs__menu a {
    margin-bottom: 20px;
  }
}

/* line 37, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a:hover {
  color: #c6c6c6;
}

/* line 40, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a:hover:before {
  width: 100%;
}

/* line 45, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a.active {
  color: #c6c6c6;
}

/* line 49, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a.active:before {
  width: 100% !important;
}

/* line 54, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a:before {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #ed1c24;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 0;
  pointer-events: none;
  transition: all .3s ease-in-out;
}

/* line 70, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__menu a:not(:hover):before {
  width: 0;
  right: 0;
  left: auto;
}

/* line 80, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content div {
  transition: all 300ms ease;
  display: none;
  animation: fadeIn 1s;
}

/* line 85, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content div p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

/* line 91, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content div.active {
  display: block;
}

/* line 96, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content li {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #c6c6c6;
  margin-bottom: 8px;
  padding-left: 10px;
}

/* line 103, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-tabs.scss */
.c-tabs__content li:before {
  content: '-';
  color: #ed1c24;
  margin-right: 5px;
  margin-left: -10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* line 3, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
body.contact .c-sticky__cta {
  display: none;
}

/* line 9, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact {
  padding: 80px 0px;
}

/* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact .flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 815px) {
  /* line 12, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact .flex {
    flex-flow: column wrap;
  }
}

/* line 23, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__form {
  width: 62%;
}

@media (max-width: 992px) {
  /* line 23, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__form {
    width: 55%;
  }
}

@media screen and (max-width: 815px) {
  /* line 23, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__form {
    width: 100%;
  }
}

/* line 35, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info {
  width: 30%;
}

@media (max-width: 992px) {
  /* line 35, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__info {
    width: 40%;
  }
}

@media screen and (max-width: 815px) {
  /* line 35, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__info {
    width: 100%;
    order: -1;
    margin-bottom: 40px;
  }
}

/* line 48, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info p {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

@media (max-width: 479px) {
  /* line 48, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__info p {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

/* line 60, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #2e2e2e;
}

/* line 65, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul li span,
.c-contact__info ul li a {
  display: block;
  font-family: "circular";
  color: #c6c6c6;
}

/* line 72, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul li span {
  font-size: 1.3rem;
  line-height: 1.8rem;
}

/* line 77, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul li a {
  font-size: 1.8rem;
  line-height: 2.4rem;
  transition: all 300ms ease-in-out;
}

@media (max-width: 479px) {
  /* line 77, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-contact__info ul li a {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

/* line 87, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul li a:hover {
  color: #ed1c24;
}

/* line 102, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper div:last-of-type {
  text-align: right;
}

/* line 105, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper div:last-of-type button {
  background-color: transparent;
  font-family: "circular";
  text-transform: initial;
  padding: 0px;
  display: inline-block;
  margin: 20px 0px;
}

/* line 113, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper div:last-of-type button:focus {
  outline: 0px;
}

/* line 117, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper div:last-of-type button i {
  margin-left: 8px;
}

/* line 120, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper div:last-of-type button i + p {
  display: none;
}

/* line 128, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__wrapper .ajax-loader {
  display: none !important;
}

/* line 133, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio {
  display: block;
}

/* line 136, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio input {
  display: none;
}

/* line 140, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio span {
  margin-left: 0px;
  margin-bottom: 10px;
}

/* line 145, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio label {
  display: inline-block;
}

/* line 148, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio label input[type="radio"]:checked + span {
  background-color: #ed1c24;
  color: white;
}

/* line 153, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio label span {
  display: inline-block;
  padding: 12px 25px;
  border: 2px solid #ed1c24;
  font-family: "knockout-bold";
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-right: 20px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

/* line 165, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__radio label span:hover {
  background-color: #ed1c24;
  color: white;
}

/* line 173, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__title {
  color: #c6c6c6;
  font-family: "circular";
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  /* line 173, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-form__title {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}

/* line 187, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__textbox, .c-form__textarea {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  background-color: #0c0c0c;
  border: 1px solid #212121;
  color: #c6c6c6;
  font-family: "circular";
  font-size: 14px;
  line-height: 20px;
}

/* line 199, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__textbox::placeholder, .c-form__textarea::placeholder {
  color: #c6c6c6;
  font-family: "circular";
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (max-width: 479px) {
  /* line 199, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-form__textbox::placeholder, .c-form__textarea::placeholder {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

/* line 211, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-form__textbox:focus, .c-form__textarea:focus {
  outline: 0;
}

/* line 217, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.wpcf7-response-output {
  border: 0px !important;
  padding: 0px;
  font-family: "circular";
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: #ed1c24;
}

/* line 226, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.wpcf7-response-output.wpcf7-mail-sent-ok {
  color: #76ca2d;
}

/* line 230, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
span[role="alert"] {
  margin: 5px 0px;
  font-size: 1.3rem;
}

/* line 235, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-map {
  height: 500px;
  position: relative;
}

@media (max-width: 767px) {
  /* line 235, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-map {
    height: 400px;
  }
}

@media (max-width: 479px) {
  /* line 235, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
  .c-map {
    height: 250px;
  }
}

/* line 247, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
.c-map:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  pointer-events: none;
  z-index: 5;
}

/* line 263, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-contact.scss */
body.contact .c-footer {
  display: none;
}

/* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.slick-banner {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .slick-banner {
    display: none;
  }
}

/* line 13, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.slick-nav {
  width: 100%;
  display: none;
  height: 0;
  flex-direction: row;
}

@media (max-width: 767px) {
  /* line 13, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .slick-nav {
    flex-direction: column;
  }
}

/* line 23, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.slick-nav .slick-list {
  width: 100%;
}

/* line 26, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.slick-nav .slick-list .slick-track {
  width: 100% !important;
  transform: translateX(0) !important;
}

/* line 30, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.slick-nav .slick-list .slick-track .thumbnail_slide {
  width: 15% !important;
  margin: 0 25px 25px 0;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* line 45, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: black;
  z-index: 9999;
  transform: translateX(-100%);
  transition: all 1000ms ease;
  width: 100%;
}

/* line 58, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__wrapper.active {
  overflow: auto;
  transform: translateX(0%);
}

/* line 65, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single {
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 40px;
  width: 100%;
}

/* line 77, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single:last-of-type .c-single__link {
  width: 125px;
  font-size: 0;
  pointer-events: none;
}

/* line 86, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single.active {
  display: flex;
}

@media (max-width: 992px) {
  /* line 90, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single .c-button--arrow {
    display: none;
  }
}

/* line 98, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__link span {
  color: #c6c6c6;
}

/* line 101, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__link span:hover {
  color: #ed1c24;
}

/* line 108, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__close span:first-of-type {
  color: #ed1c24;
}

/* line 111, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__close span:first-of-type:hover {
  color: red;
}

/* line 117, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__link, .c-single__close {
  cursor: pointer;
}

/* line 121, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__link span, .c-single__close span {
  transition: all 300ms ease;
}

@media (max-width: 479px) {
  /* line 127, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single__link span:last-of-type, .c-single__close span:last-of-type {
    display: none;
  }
}

/* line 133, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  width: 100%;
}

/* line 141, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__header img {
  width: 80px;
  margin: 0 auto;
}

/* line 146, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__header a {
  font-family: "circular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #c6c6c6;
  transition: all 300ms ease-in-out;
}

/* line 153, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__header a:hover {
  color: #ed1c24;
}

/* line 157, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__header a i {
  color: #ed1c24;
  margin-left: 5px;
}

/* line 164, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 767px) {
  /* line 164, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single__image {
    height: 300px;
    min-height: 300px;
  }
}

@media (max-width: 479px) {
  /* line 164, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single__image {
    height: 200px;
    min-height: 200px;
  }
}

/* line 186, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__spec {
  padding-bottom: 40px;
}

/* line 189, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__spec h2 {
  color: #ed1c24;
}

/* line 195, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__spec ul li {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #c6c6c6;
}

/* line 200, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single__spec ul li:before {
  content: '-';
  margin-right: 5px;
}

/* line 208, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single .flex {
  /*display: flex;
		flex-flow:column nowrap;
		justify-content: flex-start;
		align-items: flex-start;*/
}

@media (max-width: 767px) {
  /* line 208, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single .flex {
    flex-flow: column nowrap;
    justify-content: flex-start;
    order: 4;
    margin-top: 40px;
  }
}

/* line 223, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single .flex > div {
  width: 100%;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 223, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single .flex > div {
    width: 100%;
  }
}

/* line 233, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single h3 {
  font-family: "knockout-bold";
  font-size: 7rem;
  line-height: 7rem;
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  margin: 10px 0px;
}

@media (max-width: 992px) {
  /* line 233, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
  .c-single h3 {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

/* line 248, public/wp-content/themes/dm-metal/assets/src/scss/component/_c-single.scss */
.c-single p {
  font-size: 1.4rem;
  line-height: 2rem;
}
