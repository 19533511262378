.c-loader {
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index: 99999;
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	animation-name: loader;
	animation-duration: 1.5s;
	animation-delay: 2s;
	animation-iteration-count:1;
	animation-fill-mode: forwards;

	&:before {
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background:$red;
		transform:translateY(100%);
		animation-name: redBox;
		animation-duration: 1s;
		animation-delay: 1s;
		animation-iteration-count:1;
		animation-fill-mode: forwards;
		z-index: 12;

	}

	&:after {
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		transform:translateY(100%);
		animation-name:fade;
		animation-delay: 3s;
		animation-duration:1.5s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;

	}

	img {
		width:150px;
		animation-name: scale;
		animation-duration: 1s;
		animation-iteration-count:1;
		animation-fill-mode: forwards;
	}
}

@keyframes loader {

	0% {
		transform:translateY(0%);
	}

	100% {
		transform:translateY(-200%);
	}
}

@keyframes redBox {

	0% {
		transform:translateY(100%);
	}

	100% {
		transform:translateY(0%);
	}
}

@keyframes scale {

	0% {
		transform:scale(0.8);
	}

	100% {
		transform:scale(1);
	}
}

@keyframes fade {

	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

