.c-partners {
	padding:60px 0px 0px;

	&__list {
		display: flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items: center;

		li {
			padding:0px 20px;
			margin-bottom:40px;
			flex-basis:25%;

			@include mq('small') {
				flex-basis:33.33%;
			}

			@include mq('xsmall') {
				flex-basis:50%;
			}

			@media screen and (max-width:400px) {
				flex-basis:100%;
			}

			img {
				width:140px;
			}
		}
	}
}