.c-copyright {
	padding:60px 0px;

	ul {
		display:flex;
		flex-flow:row wrap;
		justify-content:space-between;
		align-items:center;

		@media screen and (max-width:650px) {
			flex-flow:column wrap;
			justify-content: center;
		}

		li {
			font-family: $knockout;
			font-size:1.4rem;
			line-height: 2rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			color:$white;

			@media screen and (max-width:650px) {
				text-align:center;
				margin:5px 0px;
			}

			&:first-child {

				a {
					font-family: $circular;
				}
			}

			&:last-child {
				display: flex;
				flex-flow:row wrap;
				justify-content: flex-end;
				align-items:center;

				@media screen and (max-width:650px) {
					justify-content: center;
				}

				svg {
					width:25px;
					fill:$red;
					margin: 0px 0px -8px 10px;
				}
			}

			.red {
				color:$red;
			}

			a {
				color:$white;
				transition:all 300ms ease-in-out;

				&:hover {
					color:$grey-light;;
				}
			}
		}
	}

}