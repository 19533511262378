body.contact {
	
	.c-sticky__cta {
		display: none;
	}

}

.c-contact {
	padding:80px 0px;

	.flex {
		display:flex;
		flex-flow:row wrap;
		justify-content:space-between;
		align-items:flex-start;

		@media screen and (max-width:815px) {
			flex-flow:column wrap;
		}
	}

	&__form {
		width:62%;

		@include mq('medium') {
			width:55%;
		}

		@media screen and (max-width:815px) {
			width:100%;
		}
	}

	&__info {
		width:30%;

		@include mq('medium') {
			width:40%;
		}

		@media screen and (max-width:815px) {
			width:100%;
			order:-1;
			margin-bottom:40px;
		}

		p {
			font-size:1.8rem;
			line-height: 2.6rem;

			@include mq('xsmall') {
				font-size:1.6rem;
				line-height: 2.2rem;
			}
		}

		ul {

			li {
				margin-bottom:10px;
				padding-bottom:10px;
				border-bottom:2px solid $grey-border;

				span,
				a {
					display: block;
					font-family: $circular;
					color:$grey-light;
				}

				span {
					font-size:1.3rem;
					line-height: 1.8rem;
				}

				a {
					font-size:1.8rem;
					line-height: 2.4rem;
					transition:all 300ms ease-in-out;

					@include mq('xsmall') {
						font-size:1.4rem;
						line-height: 2rem;
					}

					&:hover {
						color:$red;
					}
				}
			}
		}
	}
}



.c-form {

	&__wrapper {

		div:last-of-type {
			text-align: right;

			button {
				background-color:transparent;
				font-family: $circular;
				text-transform:initial;
				padding:0px;
				display: inline-block;
				margin:20px 0px;

				&:focus {
					outline:0px;
				}

				i {
					margin-left:8px;

					& + p {
						display: none;
					}
				}

			}
		}

		.ajax-loader {
			display: none !important;
		}
	}

	&__radio {
		display: block;

		input {
			display: none;
		}

		span {
			margin-left:0px;
			margin-bottom:10px;
		}

		label {
			display: inline-block;

			input[type="radio"]:checked + span {
				background-color:$red;
				color:white;
			}

			span {
				display: inline-block;
				padding:12px 25px;
				border:2px solid $red;
				font-family: $knockout-b;
				font-size:1.4rem;
				line-height: 2rem;
				text-transform:uppercase;
				margin-right:20px;
				transition:all 300ms ease-in-out;
				cursor:pointer;

				&:hover {
					background-color:$red;
					color:white;
				}
			}
		}
	}

	&__title {
		color:$grey-light;
		font-family: $circular;
		font-size:1.8rem;
		line-height: 2.4rem;
		font-weight: 400;
		margin-bottom:20px;

		@include mq('xsmall') {
			font-size:2.2rem;
			line-height: 2.8rem;
		}
	}

	&__textbox,
	&__textarea {
		width:100%;
		margin-top:20px;
		padding:15px;
		background-color:$grey-very-dark;
		border:1px solid $grey-border-2;
		color:$grey-light;
		font-family: $circular;
		font-size:14px;
		line-height: 20px;

		&::placeholder {
			color:$grey-light;
			font-family: $circular;
			font-size:1.4rem;
			line-height: 2rem;;

			@include mq('xsmall') {
				font-size:1.3rem;
				line-height: 2rem;
			}
		}

		&:focus {
			outline:0;
		}
	}
}

.wpcf7-response-output {
	border:0px !important;
	padding:0px;
	font-family:$circular;
	font-size:1.4rem;
	line-height:2rem;
	text-align:center;
	color:$red;

	&.wpcf7-mail-sent-ok {
		color:#76ca2d;
	}
}
span[role="alert"] {
	margin:5px 0px;
	font-size:1.3rem;
}

.c-map {
	height:500px;
	position:relative;

	@include mq('small') {
		height:400px;
	}

	@include mq('xsmall') {
		height:250px;
	}

	&:before {
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background-color:black;
		opacity: 0.3;
		pointer-events:none;
		z-index: 5;
	}
}

body.contact {

	.c-footer {
		display: none;
	}
}