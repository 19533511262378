.c-header {
	display:flex;
	flex-flow:row nowrap;
	justify-content:space-between;
	align-items:center;
	background:black;
	padding:20px 0px;
	transform:translateY(0%);

	&.hide {
		transform:translateY(-100%);
		position:fixed;
		top:0;
		left:0;
		right:0;
		z-index: 9999;
		padding:20px 30px;
	}

	&.show {
		transition: transform 300ms ease-in-out;
	}

	&.sticky {
		transform:translateY(0%);
	}

	&__logo {
		max-width: 250px;
		width: 100%;

		@include mq('xsmall') {
			max-width:160px;
		}
	}

	&__toggle {
		display: none;
		flex-flow:row wrap;
		justify-content: center;
		align-items:center;
		cursor:pointer;

		@include mq('medium') {
			display: inline-flex;
		}

		i {
			color:white;
			font-size:1.2rem;
			margin-left:4px;
			transition:all 500ms ease;

			&.first {
				transition-delay: 0ms;
			}

			&.second {
				transition-delay: 100ms;
			}

			&.third {
				transition-delay: 200ms;
			}
		}

		&:hover {

			i {

				&.first {
					color:$red;
				}

				&.second {
					color:$red;
				}

				&.third {
					color:$red;

				}
			}
		}
	}

	&__nav {
		display:flex;
		flex-flow:row wrap;
		justify-content:flex-end;
		align-items:center;

		@include mq('medium') {
			display: none;
		}

		li {
			margin-left:30px;
			padding-left:30px;

			@include mq('large') {
				padding-left:15px;
				margin-left:15px;
			}

			a {
				font-family: $knockout-b;
				text-transform: uppercase;
				font-size:1.6rem;
				line-height: 1.8rem;
				color:$white;
				position:relative;
				padding:0px 5px;
				transition:all 300ms ease-in-out;

				&:hover {
					color:$grey-light;
				}

				&:before {
					content:'';
					display: block;
					width:0px;
					height:3px;
					background:$red;
					position:absolute;
					top:0px;
					bottom:0px;
					left:0px;
					right:0px;
					margin:auto 0;
					pointer-events:none;
					transition:all 300ms ease-in-out;
				}

				&:hover:before {
					width:100%;
				}

				&:not(:hover):before {
					width:0px;
					right:0px;
					left:initial;
				}
			}

			&:last-child {
				border-left: 2px solid $grey-dark;

				a:before {
					display: none;
				}
			}
		}
	}


	&__mobile {
		display: none;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: flex-start;
		position:fixed;
		top:0;
		bottom:0;
		left:0;
		width:305px;
		background:$grey-very-dark;
		padding:30px 60px 30px 30px;
		z-index: 99999;
		transform:translateX(-100%);
		transition:all 500ms ease;

		&.active {
			transform:translateX(0%);
		}

		@include mq('medium') {
			display: flex;
		}

		&-close {
			color:white;
			width:30px;
			height: 100%;
			display: flex;
			flex-flow:row nowrap;
			justify-content: center;
			align-items:center;
			flex-shrink:0;
			position:absolute;
			top:0px;
			right:0px;
			padding:5px;
			background:$grey-mobile;
			transition:all 300ms ease;
			cursor: pointer;

			&:hover {
				background:lighten($grey-mobile, 2%);
			}

			i {
				color:$red;
			}
		}

		img {
			max-width: 200px;
			margin-bottom:40px;
		}

		&-nav {

			li,
			li a {
				font-family: $knockout-b;
				text-transform: uppercase;
				font-size: 1.8rem;
				line-height: 2.4rem;
				letter-spacing: 1px;
				transition:all 300ms ease;
			}
			
			li {
				padding:10px 0px;
				color:$red;

				a {
					color: $grey-light;

					&:hover {
						color:white;
					}
				}

				&:last-child {
					border-top: 2px solid $grey-border;
					margin-top:40px;
					width:100%;
				}
			}
		}

	}
}