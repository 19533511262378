.slick-banner{
	margin-bottom: 60px;

	@include mq('small') {
		display: none;
	}

	.c-single__image{
		//height: 550px;
	}
}

.slick-nav{
	width: 100%;
	display: none; //Change the style to flex to show the thumbnails
	height: 0; //Remove this to show the thumbnails
	flex-direction: row;

	@include mq('small'){
		flex-direction: column;
	}

	.slick-list{
		width: 100%;

		.slick-track{
			width: 100% !important;
			transform: translateX(0) !important;

			.thumbnail_slide{
				width: 15% !important;
				margin:0 25px 25px 0;
				//padding-bottom: 15%;

				background-position: center center !important;
				background-repeat: no-repeat !important;
				background-size: cover !important;
			}
		}
	}
}



.c-single__wrapper {
	position: fixed;
	top:0;
	right:0;
	left:0;
	bottom:0;
	height:100%;
	background:black;
	z-index: 9999;
	transform:translateX(-100%);
	transition:all 1000ms ease;
	width:100%;

	&.active {
		overflow: auto;
		transform:translateX(0%);
	}
}


.c-single {
	display:none;
	flex-flow:column nowrap;
	justify-content: flex-start;
	overflow-y:auto;
	padding:40px;
	width:100%;
	//height:100%;


	&:last-of-type {

		.c-single__link {
			width:125px;
			font-size:0;
			pointer-events: none;

		}
	}


	&.active {
		display: flex;
	}

	.c-button--arrow {

		@include mq('medium') {
			display: none;
		}
	}

	&__link {
		span {
			color:$grey-light;

			&:hover {
				color:$red;
			}
		}
	}

	&__close {
		span:first-of-type {
			color:$red;

			&:hover {
				color:red;
			}
		}
	}

	&__link,
	&__close {
		cursor:pointer;

		span {
			transition:all 300ms ease;
		}

		@include mq('xsmall') {

			span:last-of-type {
				display: none;
			}
		}
	}

	&__header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding-bottom:60px;
		width:100%;

		img {
			width: 80px;
			margin:0 auto;
		}

		a {
			font-family: $circular;
			font-size:1.6rem;
			line-height: 2.4rem;
			color:$grey-light;
			transition:all 300ms ease-in-out;

			&:hover {
				color:$red;
			}

			i {
				color:$red;
				margin-left:5px;
			}
		}
	}

	&__image {
		background-size:cover;
		background-repeat:no-repeat;
		background-position: center;
		position:relative;
		flex-grow:1;
		display:flex;
		flex-flow:column wrap;
		justify-content:center;
		align-items:flex-start;

		@include mq('small') {
			height:300px;
			min-height: 300px;
		}

		@include mq('xsmall') {
			height:200px;
			min-height: 200px;
		}
	}

	&__spec {
		padding-bottom:40px;

		h2 {
			color:$red;
		}

		ul {

			li {
				font-size:1.4rem;
				line-height: 2.2rem;
				color:$grey-light;

				&:before {
					content:'-';
					margin-right:5px;
				}
			}
		}
	}

	.flex {
		/*display: flex;
		flex-flow:column nowrap;
		justify-content: flex-start;
		align-items: flex-start;*/


		@include mq('small') {
			flex-flow:column nowrap;
			justify-content: flex-start;
			order:4;
			margin-top:40px;
		}


		& > div {
			width:100%;
			margin-bottom:50px;

			@include mq('small') {
				width:100%;
			}
		}
	}

	h3 {
		font-family: $knockout-b;
		font-size: 7rem;
		line-height: 7rem;
		text-transform: uppercase;
		color:white;
		font-weight: 400;
		margin:10px 0px;

		@include mq('medium') {
			font-size:5rem;
			line-height: 5.5rem;
		}
	}

	p {
		font-size:1.4rem;
		line-height: 2rem;
	}

}