.c-tabs {

	&__menu {
		margin-bottom:40px;

	    @include mq('xsmall') {
			display: flex;
			flex-flow:column wrap;
			align-items: flex-start;	    
	    }

		a {
		    font-family: knockout;
		    font-size: 2rem;
		    line-height: 2.6rem;
		    letter-spacing: 5px;
		    color:$grey;
		    text-transform: uppercase;
		    margin-right:40px;
		    font-weight:bold;
		    transition:all 300ms ease;
		    position:relative;

		    @include mq('medium') {
		    	font-size:1.5rem;
		    	line-height: 2rem
		    }

		    @include mq('small') {
		    	margin-right:20px;
		    }

		    @include mq('xsmall') {
		    	margin-bottom:20px;
		    }

		    &:hover {
		    	color:$grey-light;

		    	&:before {
	    		    width: 100%;
		    	}
		    }

		    &.active {

		    	color:$grey-light;

		    	&:before {
		    		width:100% !important;
		    	}
		    }

		    &:before {
	    	    content: "";
			    display: block;
			    width: 0;
			    height: 3px;
			    background: #ed1c24;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			    margin: auto 0;
			    pointer-events: none;
			    transition: all .3s ease-in-out;
		    }

		    &:not(:hover):before {
		    	    width: 0;
				    right: 0;
				    left: auto;
		    }
	    }
	}

	&__content {
		
		div {
			transition:all 300ms ease;
			display: none;
			animation: fadeIn 1s;

			p{
				font-size:1.6rem;
				line-height: 2.4rem;
			}


			&.active {
				display: block;
			}
		}

		li {
			font-size:1.6rem;
			line-height: 2.4rem;
			color:$grey-light;
			margin-bottom:8px;
			padding-left:10px;

			&:before {
				content:'-';
				color:$red;
				margin-right:5px;
				margin-left:-10px;
			}
		}
	}
}

@keyframes fadeIn {

	0% {
		opacity: 0;
		transform:translateY(20px);
	}

	100% {
		opacity: 1;
		transform:translateY(0px);
	}
}