.c-banner {
	background-size:cover;
	background-repeat:no-repeat;
	background-position: center;
	box-shadow: 0px 0px 0px 1px black inset;
	position:relative;

	@include mq('small') {
		height:400px !important;
	}
	
	.container {
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items: flex-start;
		height:100%;

		@include mq('small') {
			align-items: center;
		}
	}

	&.interne {

		.container {
			align-items:center;
			justify-content: center;
		}
	}

	&__content {
		display: flex;
		flex-flow:column wrap;
		align-items:flex-start;

		@include mq('small') {
			align-items: center;
			text-align:center;
		}

		h2 {
			order:-1;
			color:$grey-light;
		}
	}

	&.interne {

		h1 {
			margin-bottom:0px;
		}

		.c-banner__content {
			align-items:center;
			justify-content: center;
		}
	}

	&__scroll {
		position:absolute;
		bottom:40px;
		left:0px;
		right:0px;
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;

		@include mq('small') {
			display: none;
		}

		a {
			font-family: $circular;
			font-size:1.6rem;
			line-height: 2.4rem;
			font-weight: bold;
			color:$white;
			transition:all 300ms ease-in-out;

			&:hover {
				color:$grey-light;
			}
		}


		i {
			display: block;
			text-align:center;
			color:$red;
			font-size:3rem;
		}
	}
}