.c-equipe {
	background-size:cover;
	background-repeat:no-repeat;
	background-position: top center;
	padding:400px 0px 100px;
	position:relative;

	@include mq('medium') {
		padding:200px 0px 50px;
	}

	@include mq('small') {
		padding:100px 0px;
		text-align:center;
	}

	@include mq('xsmall') {
		padding:50px 0px;
	}

	span.title {	
		font-family:$knockout-b;
		text-transform:uppercase;
		margin-top:0px;
		margin-bottom:20px;
		font-size:6rem;
		line-height:6.5rem;
		color:$white;

		@media screen and (min-width:1300px) {
			font-size:7rem;
			line-height: 7.5rem;
		}

		@include mq('small') {
			font-size:4.5rem;
			line-height: 5rem;
		}

		@include mq('xsmall') {
			font-size:3.5rem;
			line-height: 4rem;
		}
	}

	p {
		font-size:1.8rem;
		line-height: 2.4rem;
		max-width: 350px;
		margin-bottom:40px;

		@include mq('small') {
			margin:20px auto 30px;
		}
	}

	&__cta {
		position:absolute;
		bottom:0px;
		right:0px;
		padding: 50px;
		background:#0d0d0d;
		z-index: 1;

		@include mq('small') {
			background:transparent;
			position:initial;
			padding:0px;
		}
	}
}