.c-sticky__cta {
	width:60px;
	height:200px;
	background-color:$red;
	position:fixed;
	top:0px;
	bottom:0px;
	margin:auto 0;
	right:0px;
	transition:all 300ms ease-in-out;
	z-index: 7;
	transform:translateX(0%);

	@include mq('small') {
		display: none;
	}

    &:hover {
    	background-color:lighten($red, 5%);
    }

    &.slideOut {
		transform:translateX(100%);
    }

	a {
	    color: #fff;
	    font-family: knockout-bold;
	    font-size:1.8rem;
	    text-transform: uppercase;
	    transform: rotate(-90deg);
	    height: 100%;
	    width: 200px;
	    display: flex;
	    flex-flow: row wrap;
	    justify-content: center;
	    padding-top: 20px;
	    transition:all 300ms ease-in-out;

	}
}