body.services {

	.c-inner-listing {
		
		ul {

			li:last-of-type {
				border-bottom:0px;
				margin-top:20px;
			}
		}
	} 
}

.c-inner-listing {
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;

	&__img {
	    height: 700px;
	    width: 100%;
	    background-size: cover;
	    background-position: top center;
	    background-repeat:no-repeat;

	    @include mq('medium') {
	    	height:400px;
	    }

	    @include mq('small') {
	    	height:300px;
	    }

	    @include mq('xsmall') {
	    	height:200px;
	    }
	}

	.container.flex {
		display: flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items: flex-start;
		padding-top:60px;
		padding-bottom:60px;
		background-size:cover;
		background-repeat:repeat;

		@include mq('small') {
			flex-flow:column wrap;
			padding-top:20px;
		}
	}

	&__list {
		width:33%;
		background:$grey-very-dark;
		padding:53px;
		margin-top:-150px;
		
		@include mq('medium'){
			padding:50px 30px;
		} 

		@include mq('small') {
			width:100%;
			margin-top:0px;
			margin-bottom:40px;
		}

		&.higher {
			margin-top:-300px;

			@include mq('small') {
				margin-top:0px;
			}
		}

		ul {

			li {
				padding:20px 0px;
				font-family: $circular;
				border-bottom:2px solid $grey-border;
				color:$grey-light;
				font-size:1.6rem;

				a {
					color:$grey-light;
					transition:all 300ms ease;

					&:hover {
						color:$red;
					}

					i {
						color:$red;
						margin-left:10px;
					}
				}
			}
		}
	}

	&__content {
		width:60%;

		@include mq('small') {
			width:100%;
		}

		p {
			font-size:1.8rem;
			line-height: 2.6rem;
		}
	}

	.c-tabs {
		width:62%;

		@include mq('small') {
			width:100%;
			padding:0px 10px;
		}
	}
}