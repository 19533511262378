.c-realisation {
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;

	&.mb {
		margin-bottom:0px;
	}

	& > a {
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		overflow:hidden;
		position:relative;

		&:before {
			content:'';
			position:absolute;
			top:0px;
			bottom:0px;
			right:0px;
			left:0px;
			z-index: 2;
			background: linear-gradient(45deg, rgba(237,28,36,0.7) 0%,rgba(0,0,0,0.5) 100%);
			opacity: 0;
			transition:all 300ms ease-in-out;
		}

		&:hover {

			&:before {
				opacity: 0.7;
			}

			.c-realisation__bar {
				bottom:0px;
			}
		}
	}

	&__half {
		width:50%;
		height:350px;

		@include mq('small') {
			width:100%;
			height:300px;
		}

		@include mq('xsmall') {
			height:200px;
		}
	}

	&__third {
		width:calc(100% / 3);
		height:300px;

		@include mq('small') {
			width:100%;
		}

		@include mq('xsmall') {
			height:200px;
		}
	}

	&__bar {
		width:100%;
		padding:20px;
		background-color:black;
		position:absolute;
		bottom:-65px;
		left:0px;
		right:0px;
		display: flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items: center;
		transition:all 300ms ease-in-out;
		z-index: 3;

		span.project-name {
			font-family: $knockout-b;
			font-size:1.8rem;
			line-height:2.2rem;
			text-transform: uppercase;
			color:$white;
		}

		span.cta {
			font-family: $circular;
			font-size:1.6rem;
			line-height:2rem;
			color:$white;

			@include mq('xsmall') {
				font-size:1.3rem;
				line-height: 1.8rem;
			}

			span {
				color:$red;
				font-weight: bold;
				display: inline-block;
				margin-left:10px;
			}
		}
	}

	&--arrow {

	}

	&__dispatch {
		flex:1;
		text-align:center;
		padding:10px;
		border-bottom:1px solid darken($grey-dark, 5%);
		background-repeat:no-repeat;
		background-position:center;
		background-size:cover;

		.c-button {
			font-family: $circular;
			text-transform:initial;
		}
	}
}