.c-footer {
	background-size:cover;
	background-repeat:no-repeat;
	background-position: center;
	position:relative;
	padding:120px 20px;
	display:flex;
	flex-flow:column wrap;
	justify-content:center;
	align-items:flex-start;
	position:relative;

	@include mq('medium') {
		text-align:center;
	}

	@include mq('small') {
		padding:60px 20px;
	}

	.container {
		padding:0px 0px;
	}

	span.title {	
		font-family:$knockout-b;
		text-transform:uppercase;
		margin-top:0px;
		margin-bottom:20px;
		font-size:6rem;
		line-height:6.5rem;
		color:$white;

		@media screen and (min-width:1300px) {
			font-size: 7rem;
			line-height: 7.5rem;
		}

		@include mq('medium') {
			font-size:4.5rem;
			line-height: 5rem;
		}

		@include mq('small') {
			font-size:3.5rem;
			line-height: 4rem;
		}
	}

	p {
		font-size:1.8rem;
		line-height: 2.4rem;
		max-width: 350px;
		margin-bottom:0px;

		@include mq('medium') {
			margin:20px auto 30px;
		}
	}

	&__cta {
		position:absolute;
		bottom:0px;
		right:0px;
		padding: 50px;
		background:black;
		z-index: 1;

		@include mq('medium') {
			background:transparent;
			position:initial;
			padding:0px;
		}
	}

}