html {
	font-size:10px;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	background: black;
	font-size: $font-size-base;
	line-height: $line-height-base;
	padding:0px 30px;
	margin:0px;
	position:relative;

	@include mq('xsmall') {
		padding:0px 20px;
	}
}

* {
	box-sizing:border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
	list-style-type:none;
	padding-left:0px;
	margin:0px;
}

.container {
    max-width: 1140px;
    width:100%;
    margin: 0 auto;
    padding: 0 20px;
}

h1, h2 {
	text-transform:uppercase;
	margin-top:0px;
	margin-bottom:20px;
}

h1 {
	font-family:$knockout-b;
	font-size:6rem;
	line-height:6.5rem;
	color:$white;
	font-weight:400;

	@include mq('small') {
		font-size:4.5rem;
		line-height: 5rem;
	}

	@include mq('xsmall') {
		font-size:3.5rem;
		line-height: 4rem;
	}

	&.size-up {
		font-size:8.5rem;
		line-height: 8.5rem;

		@media screen and (min-width:1200px) {
			font-size:9.5rem;
			line-height: 9.5rem;
		}

		@include mq('small') {
			font-size:5.5rem;
			line-height: 6rem;
		}

		@include mq('xsmall') {
			font-size:3.5rem;
			line-height: 4rem;
		}
	}
}

h2 {
	font-family:$knockout;
	font-size:1.6rem;
	line-height:2rem;
	letter-spacing: 5px;
	color:$grey;

	@include mq('small') {
		font-size:1.4rem;
		line-height: 1.8rem;
	}

	&.size-up {
		font-size:1.8rem;
		line-height: 2.4rem;

		@include mq('small') {
			font-size:1.6rem;
			line-height: 2.2rem;
		}
	}
}

h3 {
	font-weight:bold;
	font-family:$circular;
	font-size:1.2rem;
	line-height:1.8rem;
	color:$grey-light;
}

p {
	font-family:$circular;
	font-size:1.2rem;
	line-height:1.8rem;
	color:$grey-light;

	&.size-up {
		font-size:2.8rem;
		line-height: 3.4rem;
		color:$white;
		max-width:555px;
		margin:0px 0px 40px;
	
		@include mq('small') {
			font-size:2.4rem;
			line-height: 3rem;
		}
	}
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; }
	&:focus,
	&:active { color: $color-link-active; }
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: black;
	color: white;
	text-shadow: none;
}

::selection {
	background: black;
	color: white;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: black;
	opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: black;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: black;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: black;
	opacity: 1;
}
